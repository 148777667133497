html{
    min-height: 100%;
    margin: 0
}
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: sans-serif;
    overscroll-behavior:none;
  }
ol {
  /* Remove defaults */
  margin: 0;
  padding: 10px;
  list-style: none;
    
  /* Use CSS grid for spacing between list items */
  display: grid;
  gap: 20px;
  
  /* Create counter */
  counter-reset: list;
}
li {
  
  display: flex;
  align-items: baseline;
  margin: 10px;
  /* Increment counter */
  counter-increment: list;
}

ol li::before {
  content: counter(list);
  margin-right: 1.5rem;
  background: white;
  color: black;
  width: 1.75em;
  font-family: 'Courier New';
  font-weight: 900;
  min-width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5em;
  mix-blend-mode: screen;
}
#root{
    min-height: 100vh;

}
